import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    const [data, setData] = useState({
        amount: '',
        reason: '',
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    const [balance, setBalance] = useState('-')

    useEffect(() => {
        // Check if accounts and selectedAccount are defined
        if (props.accounts && props.selectedAccount !== undefined && props.accounts[props.selectedAccount]) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
    
            // Get dealerships
            props.tokenSafeAPIRequest('/account/manage/' + props.accounts[props.selectedAccount].id, requestOptions, '', (result) => {
                console.log(result);
                setBalance(result?.balance);
            });
        }
    }, [props]);
    

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Balance</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500'}}>Current Balance</span>
                    <span style={{fontWeight: '500', marginTop: '10px'}}>{balance === undefined ? '-':'$' + Math.round(balance * 100) / 100}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '20px'}}>
                    <span style={{fontWeight: '500'}}>Adjustment Amount</span>
                    <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'amount')} Style={{marginTop: '10px'}} data={data.amount} prefix='$'></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '20px'}}>
                    <span style={{fontWeight: '500'}}>Reason</span>
                    <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'reason')} Style={{marginTop: '10px'}} data={data.reason} prefix=''></TextInput>
                </div>
                <Button onClick={() => props.adjustAccount(data)} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Apply Adjustment</Button>
            </div>
        </div>
    )
}

export default Main
