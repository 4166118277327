import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [cookies, setCookies] = useCookies([])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [refresh, setRefresh] = useState(false)

    const refreshData = () => {
        setRefresh((refresh) => !refresh)
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const [spliteeTableTitles, setSpliteeTableTitles] = useState([
        {
            title: 'ID',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 0,
        },
        {
            title: 'Subagent',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={(e) => ''} index={props.index} indexParent={props.indexParent} data={dropdownData[props.index]} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Remove',
            displayElement: (props) => (<IconButton onClick={() => deleteRow(props.index, props.tableData)} image={Delete} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: undefined,
        },
    ])

    const [spliteeTableValues, setSpliteeTableValues] = useState([
        [spliteeTableTitles[0].default], 
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default], 
    ])

    const [tableData, setTableData] = useState([
        [spliteeTableTitles[0].default],
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const [spliteeRowSelected, setSpliteeRowSelected] = useState([false])
    const [deletedAccountsInfo, setDeletedAccountsInfo] = useState([]);

    const onTableChange = (e,x,y) => {
        console.log('Table Updated', e, x, y)
        setSpliteeTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const addRow = () => {
        console.log('Add Row!')
        setSpliteeTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
    }

    const [deleted, setDeleted] = useState(0)

    const deleteRow = (newIndex, currentTableData) => {

        console.log(currentTableData)

        setDeletedAccountsInfo((deletedAccountsInfo) => [...deletedAccountsInfo, currentTableData[2][newIndex]])

        setSpliteeTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    useEffect(() => {
        if (props.accounts && props.selectedAccount !== undefined && props.accounts[props.selectedAccount]) {
            console.log(props.accounts[props.selectedAccount])

            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }

            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            props.tokenSafeAPIRequest('/account/manage/' + props.accounts[props.selectedAccount].id + '?fetch_links=true', requestOptions, tokenValues, (result, status) => {
                console.log(result)
                let tempDealerList = [
                    [],
                    [],
                    [],
                ]
                let tempDealerListData = [
                    [],
                    [],
                    [],
                ]
                result.user_IDs.map((user, index) => {
                    tempDealerList[0].push(user.code)
                    tempDealerList[1].push(user.first_name + ' ' + user.last_name)
                    tempDealerList[2].push('')

                    tempDealerListData[0].push(user.code)
                    tempDealerListData[1].push(user.id)
                    tempDealerListData[2].push('')
                })

                setSpliteeTableValues(tempDealerList)
                setTableData(tempDealerListData)
            })
        }
    },[props.accounts, props.selectedAccount])

    useEffect(() => {
        //this call is heavy so I seperated it from the refresh useEffect as this would never get updated by anything on the page
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                subagent: true,
            })
          };
      
          props.tokenSafeAPIRequest('/user/search?limit=9999', requestOptions, tokenValues, (result) => {
              console.log(result)
              let tempDealerList = []
              result.map((user, index) => {
                  tempDealerList.push({value: user.id, label: user.name})
              })
              setSpliteeTableTitles((tableTitles) => {
                  return tableTitles.map((title) => {
                      if (title.title === 'Subagent')
                      {
                          return {
                              ...title,
                              displayElement: (props) => (
                                <Dropdown
                                  colors={props.colors}
                                  onChange={(e) => props.onChange(e, props.indexParent, props.index)}
                                  index={props.index}
                                  indexParent={props.indexParent}
                                  data={tempDealerList} 
                                  width={195}
                                  value={props.data}
                                >
                                  Test
                                </Dropdown>
                              ),
                          };
                      }
                      return title;
                  })
              })
          })
    },[])

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: '500px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Subagents</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={spliteeTableValues} tableInfo={spliteeTableTitles} dropdownData={dropdownData} rows={spliteeRowSelected} setRows={setSpliteeRowSelected} onChange={onTableChange} selectMenu={false} key="Modal"></Table>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button onClick={addRow} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                    <span style={{marginTop: '4px', marginLeft: '15px', fontWeight: '500'}}>Add Subagent</span>
                </div>
                <Button onClick={() => props.editSubagents(tableData)} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '250px', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
