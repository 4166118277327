import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import Dropdown from '../elements/ReactSelectDropdown'

import ModalManager from '../elements/ModalManager'
import FiltersModal from '../assets/metrics/FiltersModal'

import ExportData from '../assets/metrics/ExportData'

import CalendarModal from '../assets/metrics/CalendarModal'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const list = ['Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski']

    // const headerValues = [
    //     {
    //         title: 'Total Amount',
    //         value: '$5,230'
    //     },
    // ]

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 0,
        },
        {
            title: 'Spiffee',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Agent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Spiff Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '10/24/23',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default], 
        [tableTitles[7].default], 
        [tableTitles[8].default], 
    ])

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [cookies, setCookies] = useCookies([])

    const [headerValues, setHeaderValues] = useState([])
    const [allSpiffees, setAllSpiffees] = useState([])
    const [selectedSpiffee, setSelectedSpiffee] = useState({value: '', label: 'All Spiffees'})
    const [skip, setSkip] = useState(0)
    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')
    const [filterData, setFilterData] = useState([
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'user_ID',
        },
        {
            title: 'Dealership',
            data: '',
            value: {label: '', value: ''},
            key: 'dealership_ID',
        },
        {
            title: 'Admin',
            data: '',
            value: {label: '', value: ''},
            key: 'admin_ID',
        },
        {
            title: 'Report Type',
            data: [
                {value: 'Saturday', label: 'Saturday'},
                {value: 'PreSpiff', label: 'PreSpiff'},
                {value: 'Reserve Release', label: "Reserve Release"}
            ],
            value: {label: '', value: ''},
            key: 'report_type',
        },
        {
            title: 'Spiff Type',
            data: [
                {value: 'Check', label: 'Check'},
                {value: 'Visa', label: 'Visa'},
                {value: 'Amex', label: 'Amex'},
            ],
            value: {label: '', value: ''},
            key: 'spiff_type',
        },
    ])

    function combineObjectsWithSameCode(arr) {
        const combined = arr.reduce((acc, obj) => {
          const key = obj.report_code; //CHANGE IS KEY IS DIFFERENT FOR OTHER OBJECTS
      
          if (!acc[key]) {
            // Initialize with the current object, set quantity to 1, and start the ID array with the current ID.
            acc[key] = { ...obj, quantity: 1, IDs: [obj.id] };
          } else {
            // If the key exists, increment the quantity and push the current ID into the ID array.
            acc[key].quantity += 1;
            acc[key].IDs.push(obj.id);
          }
      
          // Remove the single ID property since we now have an IDs array.
          delete acc[key].id;
      
          return acc;
        }, {});
      
        console.log(Object.values(combined))
        return Object.values(combined);
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }

        let tempDealerList = []
        let tempAdminList = []
        let tempSubagentList = []
        let tempSpiffeeList = []

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            
        })

        //get admins
        props.tokenSafeAPIRequest('/admin/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })
        })

        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        //get subagents
        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })

        })

        setFilterData([
            {
                title: 'Subagent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'user_ID',
            },
            {
                title: 'Dealership',
                data: tempDealerList,
                value: {label: '', value: ''},
                key: 'dealership_ID',
            },
            {
                title: 'Admin',
                data: tempAdminList,
                value: {label: '', value: ''},
                key: 'admin_ID',
            },
            {
                title: 'Report Type',
                data: [
                    {value: 'Saturday', label: 'Saturday'},
                    {value: 'PreSpiff', label: 'PreSpiff'},
                    {value: 'Reserve Release', label: "Reserve Release"}
                ],
                value: {label: '', value: ''},
                key: 'report_type',
            },
            {
                title: 'Spiff Type',
                data: [
                    {value: 'Check', label: 'Check'},
                    {value: 'Visa', label: 'Visa'},
                    {value: 'Amex', label: 'Amex'},
                    {value: 'giftcards', label: 'Giftcards'},
                ],
                value: {label: '', value: ''},
                key: 'spiff_type',
            },
        ])

    },[])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }
  
        props.tokenSafeAPIRequest('/spiffee/all_names?active_only=false', requestOptions, tokenValues, (result, status) => {
            let tempSpiffees = [{value: '', label: 'All Spiffees'}]
            result.map((spiffee, index) => {
                tempSpiffees.push({value: spiffee.id, label: spiffee.name})
            })
            setAllSpiffees(tempSpiffees)
        })

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            spiffee_ID: selectedSpiffee.value === '' ? null:selectedSpiffee.value,
            ...filterDataBy
          })
        }

        let tempStringAdjust = ''
        if (selectedSpiffee.value === '')
        {
            tempStringAdjust = '';
        }
        else
        {
            tempStringAdjust = '/' + selectedSpiffee.value
        }

        props.tokenSafeAPIRequest('/metrics/spiff' + tempStringAdjust + '?limit=15' + '&fetch_links=true&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.spiffs.length === 0)
            {
                setTableValues([
                    [tableTitles[0].default], 
                    [tableTitles[1].default],
                    [tableTitles[2].default],
                    [tableTitles[3].default], 
                    [tableTitles[4].default],
                    [tableTitles[5].default],
                    [tableTitles[6].default], 
                    [tableTitles[7].default], 
                    [tableTitles[8].default], 
                ])
                let tempStats = [
                    {title: 'Total Amount', value: '$' + 0},
                    {title: 'Total Spiffs', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                let tempStats = [
                    {title: 'Total Amount', value: '$' + formatNumber(result.total_amount)},
                    {title: 'Total Quantity', value: formatNumber(result.total_quantity)},
                ]

                setHeaderValues(tempStats)

                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                let tempReportID = []
                result.spiffs.map((spiffs, index) => {
                    tempTableValues[0].push(spiffs.quantity)
                    tempTableValues[1].push(spiffs.spiffee_ID === null ? 'No Spiffee':spiffs.spiffee_ID.name)
                    tempTableValues[2].push(spiffs.admin_ID === null ? 'No Admin':spiffs.admin_ID.name)
                    tempTableValues[3].push(spiffs.dealership_ID === null ? 'No Dealership':spiffs.dealership_ID.name )
                    tempTableValues[4].push(spiffs.user_ID === null ? 'No User':spiffs.user_ID.first_name + ' ' + spiffs.user_ID.last_name)
                    tempTableValues[5].push(spiffs.report_type)
                    tempTableValues[6].push(spiffs.spiff_type)
                    tempTableValues[7].push(spiffs.original_amount)
                    tempTableValues[8].push(formatDate(new Date(spiffs.timestamp)))
                    tempReportID.push([spiffs.id, spiffs.report_code])
                })

                setTableValues(tempTableValues)
            }
        })
        setSkip(0)
    },[selectedSpiffee, filterData, sort, calStartDate, calEndDate])

    const countDuplicateSubarrays = (arrayOfArrays) => {
        const arrayMap = new Map();
      
        arrayOfArrays.forEach(subArray => {
          // Convert each subarray to a string to use as a unique key for comparison
          const key = JSON.stringify(subArray);
          if (arrayMap.has(key)) {
            arrayMap.set(key, arrayMap.get(key) + 1);
          } else {
            arrayMap.set(key, 1);
          }
        });
      
        // Convert the map back into an array of arrays with quantities
        return Array.from(arrayMap, ([key, value]) => [JSON.parse(key), value]);
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const updateSelectedSpiffee = (spiffee) => {
        console.log(spiffee)
        setSelectedSpiffee(spiffee)
        //updateStatisticsAndTable(agent)
    }

    const onBottom = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }
  
        props.tokenSafeAPIRequest('/spiffee/all_names?active_only=false', requestOptions, tokenValues, (result, status) => {
            let tempSpiffees = [{value: '', label: 'All Spiffees'}]
            result.map((spiffee, index) => {
                tempSpiffees.push({value: spiffee.id, label: spiffee.name})
            })
            setAllSpiffees(tempSpiffees)
        })

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            spiffee_ID: selectedSpiffee.value === '' ? null:selectedSpiffee.value,
            ...filterDataBy
          })
        }

        let tempStringAdjust = ''
        if (selectedSpiffee.value === '')
        {
            tempStringAdjust = '';
        }
        else
        {
            tempStringAdjust = '/' + selectedSpiffee.value
        }

        props.tokenSafeAPIRequest('/metrics/spiff' + tempStringAdjust + '?limit=15&skip=' + (skip+15) + '&fetch_links=true&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.spiffs.length === 0)
            {
                return '';
            }
            else
            {

                let tempTableValues = tableValues

                let tempReportID = []
                result.spiffs.map((spiffs, index) => {
                    tempTableValues[0].push(spiffs.quantity)
                    tempTableValues[1].push(spiffs.spiffee_ID === null ? 'No Spiffee':spiffs.spiffee_ID.name)
                    tempTableValues[2].push(spiffs.admin_ID === null ? 'No Admin':spiffs.admin_ID.name)
                    tempTableValues[3].push(spiffs.dealership_ID.name)
                    tempTableValues[4].push(spiffs.user_ID.first_name + ' ' + spiffs.user_ID.last_name)
                    tempTableValues[5].push(spiffs.report_type)
                    tempTableValues[6].push(spiffs.spiff_type)
                    tempTableValues[7].push(spiffs.original_amount)
                    tempTableValues[8].push(formatDate(new Date(spiffs.timestamp)))
                    tempReportID.push([spiffs.id, spiffs.report_code])
                })

                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip + 15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        admin_ID: '',
        user_ID: '',
        dealership_ID: '',
        report_type: '',
        spiff_type: '',
        date: '',
        gift_cards: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        admin_ID: '',
        user_ID: '',
        dealership_ID: '',
        report_type: '',
        spiff_type: '',
        date: '',
        gift_cards: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'amount', label: 'Amount' },
        { value: 'timestamp', label: 'Date' },
    ]

    const sortToName = {
        timestamp: 'Date',
        amount: 'Amount',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'quantity', label: 'QTY'}, {id: 'spiffee_ID.name', label: 'Spiffee'}, {id: 'admin_ID.name', label: 'Admin'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'user_ID.first_name', label: 'Agent First Name'}, {id: 'user_ID.last_name', label: 'Agent Last Name'}, {id: 'report_type', label: 'Report Type'}, {id: 'spiff_type', label: 'Spiff type'}, {id: 'amount', label:'amount'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            spiffee_ID: selectedSpiffee.value === '' ? null:selectedSpiffee.value,
            start_date: startDate,
            ...filterDataBy
        }
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }


    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div></div>{/* <OutputColumn colors={props.colors} list={list} title={"Subagents"}></OutputColumn> */}
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Dropdown colors={props.colors} onChange={(e) => updateSelectedSpiffee(e)} index={props.index} indexParent={props.indexParent} data={allSpiffees} width={195} value={selectedSpiffee.label}>Test</Dropdown>
                    <span onClick={() => console.log(filterData)} style={{fontWeight: '600', marginLeft: '20px', marginTop: '6px'}}>Filter Spiffees</span>
                    <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week</Button>
                </div>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} removeFilter={removeFilter} filterData={filterData} sort={sort} setSort={setSort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <ExportData colors={props.colors} calStartDate={calStartDate} calEndDate={calEndDate} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/spiff'} specific={selectedSpiffee.value} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Spiffees Report'}></ExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
