import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const seperateCityAndState = (address) => {
        const parts = address.split(', ');
        // Check if the array has two parts: city and state
        if (parts.length === 2) {
            // Return an array with the city and state
            return [parts[0], parts[1]];
        } else {
            // If the format is not as expected, return an error message or handle accordingly
            throw new Error('Invalid location format. Expected format: "City, State".');
        }
    }

    const [data, setData] = useState({
        first_name: props.subagents[props.selectedSubagent].first_name !== null ? props.subagents[props.selectedSubagent].first_name : '',
        last_name: props.subagents[props.selectedSubagent].last_name !== null ? props.subagents[props.selectedSubagent].last_name : '',
        email: props.subagents[props.selectedSubagent].email !== null ? props.subagents[props.selectedSubagent].email : '',
        address: props.subagents[props.selectedSubagent].address !== null ? props.subagents[props.selectedSubagent].address : '',
        state_code: props.subagents[props.selectedSubagent].state_code !== null ? props.subagents[props.selectedSubagent].state_code : '',
        city: props.subagents[props.selectedSubagent].city !== null ? props.subagents[props.selectedSubagent].city : '',
        phone_number: props.subagents[props.selectedSubagent].phone_number !== null ? props.subagents[props.selectedSubagent].phone_number : '',
        SSN: props.subagents[props.selectedSubagent].SSN !== null ? props.subagents[props.selectedSubagent].SSN : '',
        username: props.subagents[props.selectedSubagent].username !== null ? props.subagents[props.selectedSubagent].username : '',
        password: '',
        resource_state: props.subagents[props.selectedSubagent].resource_state !== null ? props.subagents[props.selectedSubagent].resource_state : 0,
        id: props.subagents[props.selectedSubagent].id !== null ? props.subagents[props.selectedSubagent].id : '',
        territory_input: '',
        territories: props.subagents[props.selectedSubagent].territories !== null ? props.subagents[props.selectedSubagent].territories : [], 
        resource_state: props.subagents[props.selectedSubagent].resource_state !== null ? props.subagents[props.selectedSubagent].resource_state : 0,
        zip_code: props.subagents[props.selectedSubagent].zip_code !== null ? props.subagents[props.selectedSubagent].zip_code : ''
    })

    console.log(data)

    const onDataChange = (e, value) => {
        console.log(e, value)
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    const addTerritory = () => {
        setData(prevData => ({
            ...prevData,
            territories: [...prevData.territories, prevData.territory_input],
            territory_input: ''
        }))
    }

    const deleteTerritory = (index) => {
        console.log('Deleting Index: ' + index)
        setData(prevData => ({
            ...prevData,
            territories: prevData.territories.filter((_, idx) => idx !== index)
        }))
        console.log(data.territories)
    }

    const [ssn, setSSN] = useState('')

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/view_ssn/' + props.subagents[props.selectedSubagent].id, requestOptions, '', (result, status) => {
            setSSN(result)
        })
    },[])

    return (
        <div style={{width: '715px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Subagent</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '15%', display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                <IconButton onClick={() => props.deleteSubagent(data.id)} colors={props.colors} size={'25px'} Style={{backgroundColor: props.colors.warning, padding: '3px', borderRadius: '5px'}} image={Delete}></IconButton>
                <span style={{fontWeight: '600', marginTop: '5px', marginLeft: '10px'}}>Delete Subagent</span>
            </div>
            <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>First Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'first_name')} colors={props.colors} data={data.first_name} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Last Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'last_name')} colors={props.colors} data={data.last_name} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Street</span>
                        <TextInput onChange={(e) => onDataChange(e, 'address')} colors={props.colors} data={data.address} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>City</span>
                        <TextInput onChange={(e) => onDataChange(e, 'city')} colors={props.colors} data={data.city} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Email</span>
                        <TextInput onChange={(e) => onDataChange(e, 'email')} colors={props.colors} data={data.email} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>State</span>
                        <TextInput onChange={(e) => onDataChange(e, 'state_code')} colors={props.colors} data={data.state_code} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>SSN</span>
                        <TextInput onChange={(e) => setSSN(e)} colors={props.colors} data={ssn} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Zip Code</span>
                        <TextInput onChange={(e) => onDataChange(e, 'zip_code')} colors={props.colors} data={data.zip_code} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Phone Number</span>
                        <TextInput onChange={(e) => onDataChange(e, 'phone_number')}  colors={props.colors} data={data.phone_number} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Username</span>
                        <TextInput onChange={(e) => onDataChange(e, 'username')} colors={props.colors} data={data.username} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Password</span>
                        <TextInput onChange={(e) => onDataChange(e, 'password')} colors={props.colors} data={data.password} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', width: '50%', marginTop: '20px'}}>
                        <div onClick={() => onDataChange(data.resource_state === 0 ? 1:0, 'resource_state')} style={{cursor: 'pointer', width: '20px', height: '20px', border: data.resource_state === 0 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: data.resource_state === 0  ? props.colors.primary:'', borderRadius: '5px'}}></div>
                        <span style={{fontWeight: '500', marginLeft: '10px'}}>Mark as Inactive</span>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontWeight: '500'}}>Territories</span>
                            <TextInput colors={props.colors} onChange={(e) => onDataChange(e.toUpperCase(), 'territory_input')} data={data.territory_input} prefix='' Style={{height: '25px', width: '30px', padding: '0px', marginLeft: '10px'}}></TextInput>
                            <Button onClick={() => addTerritory()} colors={props.colors} Style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '3px',  marginLeft: '5px', height: '25px', paddingTop: '0px', paddingBottom: '0px'}}>+</Button>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                            {data.territories.map((territory, index) => { 
                                return <span onClick={() => deleteTerritory(index)} style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '3px', padding: '1px 5px 1px 5px', margin: '3px', cursor: 'pointer'}}>{territory}</span>
                            })}
                        </div>
                    </div>
                </div>
                <Button onClick={() => props.editSubagent({...data, SSN: ssn})} colors={props.colors} active={true} Style={{width: '150px', marginLeft: 'auto'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
