import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import DownArrow from '../media/icons/Down_Arrow.svg'

import {useFloating, autoUpdate} from '@floating-ui/react';

import Select from 'react-select';

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
            maxShow={number} - Maximum values visible
            onChange={function} - Calls function when selected option is changed (Passes in selected option name)
            width={number} - this is set outside of Styles as it is needed
    */}
    
    const [selected, setSelected] = useState(props.children)

    function calculateSimilarity(str1, str2) {
        //thanks chatGPT
        const m = str1.length;
        const n = str2.length;
        const dp = [];
    
        for (let i = 0; i <= m; i++) {
            dp[i] = [];
            for (let j = 0; j <= n; j++) {
                if (i === 0) {
                    dp[i][j] = j;
                } else if (j === 0) {
                    dp[i][j] = i;
                } else {
                    const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
                    dp[i][j] = Math.min(
                        dp[i - 1][j - 1] + cost,
                        dp[i][j - 1] + 1,
                        dp[i - 1][j] + 1
                    );
                }
            }
        }
    
        return 1 - dp[m][n] / Math.max(m, n);
    }

    const [customStyles, setCustomStyles] = useState({
        control: (provided, state) => ({
          ...provided,
          width: props.width,
          fontWeight: '500',
          color: 'black',
          fontFamily: "Montserrat, Arial, sans-serif",
          ...props.Style,
        }),
      });

    const onValChange = (e) => {
        props.onChange(e, props.index, props.indexParent)
    }

    return (
        <>
            <Select
                isSearchable
                styles={customStyles}
                defaultValue={selected}
                onChange={(e) => onValChange(e)}
                options={props.data}
                value={{value: props.value, label: props.value }}
                isDisabled={props.isDisabled}
                maxMenuHeight={200}
                menuPlacement={props.menuPlacement}
            />
        </>

    )
}

export default Main
