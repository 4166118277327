import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayout from '../elements/OutputLayout'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'
import ModalManager from '../elements/ModalManager'

import Weekly7Eligibility from '../assets/reportingHistory/Weekly7Eligibility'
import ViewAdminModal from '../assets/reportingHistory/ViewAdminsModal'
import ViewContractTypesModal from '../assets/reportingHistory/ViewContractTypesModal'

import FiltersModal from '../assets/metrics/FiltersModal'
import EditSaturdayReport from '../assets/metrics/EditSaturdayReport'

import IconButton from '../elements/IconButton'
import Edit from '../media/icons/icons8-edit-96.png'

import Notification from '../elements/Notification'

import ExportData from '../assets/metrics/ExportData'

import { useCookies } from 'react-cookie'

import CalendarModal from '../assets/metrics/CalendarModal'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])
    const [skip, setSkip] = useState(15)

    const list = ['4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors']

    const [headerValues, setHeaderValues] = useState([])

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']
    
    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const editSatReportClick = (selectedReport) => {
        if (localStorage.getItem('role') === 'subagent') 
        {

        }
        else
        {
            setEditSatReportModal(true); 
            setSelectedReport(selectedReport)
        }
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Account',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Total Inputs',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data.toLocaleString()}</span>),
            default: '',
        },
        {
            title: 'Total Outputs',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data.toLocaleString()}</span>),
            default: '',
        },
        {
            title: 'Total',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data.toLocaleString()}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const [sort, setSort] = useState('timestamp')
    const [filtersModal, setFiltersModal] = useState(false)
    const [saturdayReports, setSaturdayReports] = useState([])
    const [editSatReportModal, setEditSatReportModal] = useState(false)
    const [selectedReport, setSelectedReport] = useState(-1)
    const [allDealerships, setAllDealerships] = useState([])
    const [filterData, setFilterData] = useState([
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'submitted_by',
        },
        {
            title: 'Dealership',
            data: '',
            value: {label: '', value: ''},
            key: 'dealership_ID',
        },
        {
            title: 'Admin',
            data: '',
            value: {label: '', value: ''},
            key: 'admin_ID',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
    ])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }

        let tempDealerList = []
        let tempAdminList = []
        let tempSubagentList = []
        let tempContractList = []

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })  
            setAllDealerships(tempDealerList)
        })

        //get admins
        props.tokenSafeAPIRequest('/admin/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })
        })

        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        //get subagents
        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })

        })

        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

          //contract types
        props.tokenSafeAPIRequest('/admin/contract_types?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.VSC_contracts.map((type, index) => {
                tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
            })
            result.ancillary_contracts.map((type, index) => {
                tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
            })
        })

        setFilterData([
            {
                title: 'Subagent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'submitted_by',
            },
            {
                title: 'Dealership',
                data: tempDealerList,
                value: {label: '', value: ''},
                key: 'dealership_ID',
            },
            {
                title: 'Admin',
                data: tempAdminList,
                value: {label: '', value: ''},
                key: 'admin_ID',
            },
            {
                title: 'Contract Type',
                data: tempContractList,
                value: {label: '', value: ''},
                key: 'contract_type',
            },
        ])
    },[])

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    const refreshAllData = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                console.log(category.value.value)
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }
        props.tokenSafeAPIRequest('/metrics/account/totals?fetch_links=true&limit=' + skip + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.accounts.length === 0)
            {
                setTableValues([
                    [], 
                    [], 
                    [], 
                    [], 
                ])
                let tempStats = [
                    {title: 'Total Inputs', value: 0},
                    {title: 'Total Outputs', value: '$' + 0},
                    {title: 'Total Amount', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                setSaturdayReports(result)
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [], 
                ]
                let tempStats = [
                    {title: 'Total Inputs', value: '$' + formatNumber(result.total_inputs)},
                    {title: 'Total Outputs', value: '$' + formatNumber(result.total_outputs)},
                    {title: 'Total Amount', value: '$' + formatNumber(result.total_amount)},
                ]
                setHeaderValues(tempStats)
                let tempReportID = []
                result.accounts.map((report, index) => {
                    tempTableValues[0].push(report.name)
                    tempTableValues[1].push(report.total_inputs)
                    tempTableValues[2].push(report.total_outputs)
                    tempTableValues[3].push(report.total_amount)
                    tempReportID.push([report.id, report.code])
                })

                console.log(tempTableValues)

                setTableValues(tempTableValues)
            }
        })
    }

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                console.log(category.value.value)
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }
        props.tokenSafeAPIRequest('/metrics/account/totals?fetch_links=true&limit=999999999999999999' + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.accounts.length === 0)
            {
                setTableValues([
                    [],
                    [], 
                    [], 
                    [], 
                ])
                let tempStats = [
                    {title: 'Total Inputs', value: 0},
                    {title: 'Total Outputs', value: '$' + 0},
                    {title: 'Total Amount', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                setSaturdayReports(result)
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [], 
                ]
                let tempStats = [
                    {title: 'Total Inputs', value: '$' + formatNumber(result.total_inputs)},
                    {title: 'Total Outputs', value: '$' + formatNumber(result.total_outputs)},
                    {title: 'Total Amount', value: '$' + formatNumber(result.total_amount)},
                ]
                setHeaderValues(tempStats)
                let tempReportID = []
                result.accounts.map((report, index) => {
                    tempTableValues[0].push(report.name)
                    tempTableValues[1].push(report.total_inputs)
                    tempTableValues[2].push(report.total_outputs)
                    tempTableValues[3].push(report.total_amount)
                    tempReportID.push([report.id, report.code])
                })

                console.log(tempTableValues)

                setTableValues(tempTableValues)
            }
        })
    },[filterData, sort, calStartDate, calEndDate])

    const onBottom = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/account/totals?fetch_links=true&limit=15&skip=' + skip + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.accounts.length === 0)
            {
                return '';
            }
            else
            {
                let tempTableValues = tableValues
                let tempReportID = []
                result.accounts.map((report, index) => {
                    tempTableValues[0].push(report.name)
                    tempTableValues[1].push(report.total_inputs)
                    tempTableValues[2].push(report.total_outputs)
                    tempTableValues[3].push(report.total_amount)
                    tempReportID.push([report.id, report.code])
                })

                setSaturdayReports((saturdayReports) => saturdayReports.concat(result))
                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip+15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        admin_ID: '',
        submitted_by: '',
        dealership_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        admin_ID: '',
        submitted_by: '',
        dealership_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'contract_gross', label: 'Amount' },
        { value: 'timestamp', label: 'Date' },
    ]

    const sortToName = {
        timestamp: 'Date',
        contract_gross: 'Amount',
    }

    const processSpifftype = (spiffType) => {
        if (spiffType === '$25 Visa') return 'Visa'
        else if (spiffType === '$50 Visa') return 'Visa'
        else if (spiffType === '$100 Visa') return 'Visa'
        else {return spiffType}
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const [notification, setNotification] = useState('')

    const editSaturdayReport = (data) => {
        console.log(data)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = (
            {
                'spiffs': data.spiffees.map((spiffee, index) => {
                    return {
                        'address_type': spiffee.id === 'dealership_spiff' ? 1:spiffee.address.value,
                        'spiff_type': processSpifftype(spiffee.spiff_type),
                        'amount': spiffee.amount,
                        'ATTN': spiffee.ATTN,
                        'dealership_spiff': spiffee.dealership_spiff,
                        'spiffee_ID': spiffee.dealership_spiff ? null:spiffee.spiffee.value,
                    }
                }),
                'reserve_inputs': data.reserve_inputs.map((input, index) => {
                    return {
                        'account_ID': input.account.value,
                        'amount': input.amount,
                        'reason': input.reason,
                    }
                }),
            }
        )
        
        console.log(raw)

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(raw)
        }

        props.tokenSafeAPIRequest('/report/saturday/' + data.code, requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {
                console.log('hmm')
                setEditSatReportModal(false)
                setNotification('Edited Saturday Report Successfully at: ' + getCurrentTimeFormatted())
                refreshAllData();
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'name', label: 'Account'}, {id: 'total_inputs', label: 'Total Inputs'}, {id: 'total_outputs', label: 'Total Outputs'}, {id: 'total_amount', label: 'Total'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            ...filterDataBy,
            start_date: startDate
        }
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    return (
        <OutputLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week 2</Button>
                {/* <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} removeFilter={removeFilter} filterData={filterData} sort={sort} setSort={setSort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter> */}
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => ''}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <EditSaturdayReport colors={props.colors} isOpen={editSatReportModal} setIsOpen={() => setEditSatReportModal(false)} editSaturdayReport={editSaturdayReport} saturdayReports={saturdayReports} selectedReport={selectedReport} allDealerships={allDealerships} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditSaturdayReport>
                <Notification colors={props.colors} message={notification}></Notification>
                <ExportData colors={props.colors} calStartDate={calStartDate} calEndDate={calEndDate} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/account/totals'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'List of Reserve Account Totals Report'}></ExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </OutputLayout>
    )
}

export default Main
