import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../../elements/UserTab.jsx'
import Button from '../../elements/Button.jsx'

import { useCookies } from 'react-cookie' 

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    console.log(props)

    const [isAgent, setIsAgent] = useState(localStorage.getItem('role') === 'subagent' ? true:false)

    function transformString(str) {
        return str
            .replace(/_/g, ' ') // Replace all underscores with spaces
            .split(' ') // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the array back into a single string
    }

    const pendingLinks = [
        {
            key: 'adjustment_reports',
            link: '/reports/adjustmentreportingospending'
            //pending for OS
        },
        {
            key: 'commission_received',
            link: '/reports/commissionreceivedospending'
            //pending for OS
        },
        {
            key: 'expense_reports',
            link: '/reports/expensepending'
            //pending for SA
        },
        {
            key: 'prespiff_reports',
            link: '/reports/prespiffreportingpending'
            //pending for SA
        },
        {
            key: 'reserve_release_reports',
            link: ''
            //pending for SA
        },
        {
            key: 'reserve_totals_reports',
            link: ''
            //pending for SA
        },
        {
            key: 'saturday_reports',
            link: '/reports/saturdayreportingpending'
            //pending for SA
        },
        {
            key: 'thursday_reports',
            link: '/reports/thursdayreportingpending'
            //pending for SA
        },
        {
            key: 'verification_reports',
            link: '/reports/thursdayreportingpending'
            //pending for SA
        },
    ]

    const approveLinks = [
        {
            key: 'adjustment_reports',
            link: '/reports/adjustmentreportingosapprove'
            //pending for OS
        },
        {
            key: 'commission_received',
            link: '/reports/commissionreceived'
            //pending for OS
        },
        {
            key: 'expense_reports',
            link: '/reports/expenseos'
            //pending for SA
        },
        {
            key: 'prespiff_reports',
            link: '/reports/prespiffreportingos'
            //pending for SA
        },
        {
            key: 'reserve_release_reports',
            link: '/reports/reservereleaserequests'
            //pending for SA
        },
        {
            key: 'reserve_totals_reports',
            link: '/reports/reservetotals'
            //pending for SA
        },
        {
            key: 'saturday_reports',
            link: isAgent ? '/reports/saturdayreportingapprove':'/reports/saturdayreportingos'
            //pending for SA
        },
        {
            key: 'thursday_reports',
            link: '/reports/thursdayreportingos'
            //pending for SA
        },
        {
            key: 'verification_reports',
            link: isAgent ? '/reports/thursdayreportingapprove':'/reports/thursdayreportingos'
            //pending for SA
        },
    ]

    function getLinkByKey(key, links) {
        console.log(key)
        const linkObject = links.find(item => item.key === key);
        return linkObject ? linkObject.link : null;
    }

    return (
        <div style={{width: '370px', height: '100vh', position: 'fixed', display: 'flex', flexDirection: 'column', borderRight: '1px solid ' + props.colors.border, marginLeft: '20px'}}>
            <div className='verticalCenter' style={{flexBasis: '75px', marginLeft: '20px', marginTop: '10px'}}>
                <UserTab colors={props.colors} baseURL={props.baseURL} updateUserTab={props.updateUserTab} tokenSafeAPIRequest={props.tokenSafeAPIRequest}>{props.name}</UserTab>
            </div>
            <div className='center' style={{flexBasis: '150px', borderBottom: '1px solid ' + props.colors.border}}>
                <span style={{color: props.colors.font, fontSize: '40px', fontWeight: '500'}}>{props.title}</span>
            </div>
            <div className='noSelect' style={{flexBasis: 'auto', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '20px', fontWeight: '500', marginTop: '20px', marginBottom: '10px'}}>Reports To Approve</span>
                {Object.keys(props.reportsToApprove).map((key) => {
                    return (
                        props.reportsToApprove[key] !== 0 && <Link to={getLinkByKey(key, approveLinks)} style={{display: 'flex', flexDirection: 'row', backgroundColor: props.colors.block, width: '90%', borderRadius: '100px', border: '1px solid ' + props.colors.border, marginBottom: '10px'}}>
                            <span style={{padding: '7px', paddingLeft: '20px', fontWeight: '500'}}>{transformString(key)}</span>
                            <div className='center' style={{marginLeft: 'auto', backgroundColor: props.reportsToApprove[key] > 0 ? props.colors.primary:'', width: '38px', borderRadius: '100px', color: props.reportsToApprove[key] > 0 ? props.colors.block:props.colors.primary, border: '2px solid ' + props.colors.primary}}><span style={{fontWeight: '500'}}>{props.reportsToApprove[key]}</span></div>
                        </Link>
                    )
                })}
                <span style={{fontSize: '20px', fontWeight: '500', marginTop: '20px', marginBottom: '10px'}}>Your Reports Waiting to be Approved</span>
                {Object.keys(props.pendingReports).map((key) => {
                    return (
                        props.pendingReports[key] !== 0 && <Link to={getLinkByKey(key, pendingLinks)} style={{display: 'flex', flexDirection: 'row', backgroundColor: props.colors.block, width: '90%', borderRadius: '100px', border: '1px solid ' + props.colors.border, marginBottom: '10px'}}>
                            <span style={{padding: '7px', paddingLeft: '20px', fontWeight: '500'}}>{transformString(key)}</span>
                            <div className='center' style={{marginLeft: 'auto', backgroundColor: props.pendingReports[key]> 0 ? props.colors.primary:'', width: '38px', borderRadius: '100px', color: props.pendingReports[key] > 0 ? props.colors.block:props.colors.primary, border: '2px solid ' + props.colors.primary}}><span style={{fontWeight: '500'}}>{props.pendingReports[key]}</span></div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default Main
