import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [data, setData] = useState({
        name: props.Admins[props.selectedAdmin].name !== null ? props.Admins[props.selectedAdmin].name : '',
        code: props.Admins[props.selectedAdmin].code !== null ? props.Admins[props.selectedAdmin].code : '',
        id: props.Admins[props.selectedAdmin].id,
    });

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Admin Modal</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '15%', display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                <IconButton colors={props.colors} onClick={() => props.deleteAdmin(data.id)} size={'25px'} Style={{backgroundColor: props.colors.warning, padding: '3px', borderRadius: '5px'}} image={Delete}></IconButton>
                <span style={{fontWeight: '600', marginTop: '5px', marginLeft: '10px'}}>Delete Admin</span>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500'}}>Name</span>
                    <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'name')} data={data.name} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Code</span>
                    <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'code')} data={data.code} prefix=''></TextInput>
                </div>
                <Button onClick={() => props.editAdmin(data)} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
