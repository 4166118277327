import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    const [data, setData] = useState({
        contract_gross: props.reportInfo[1],
        quantity: props.reportInfo[0],
        reason: '',
    })

    const onDataChange = (value, prefix) => {
        setData((data) => ({
            ...data,
            [prefix]: value,
        }))
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '20px'}}>
                    <span style={{fontWeight: '500'}}>Quantity</span>
                    <TextInput onChange={(e) => onDataChange(e, 'quantity')} colors={props.colors} data={data.quantity} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '20px'}}>
                    <span style={{fontWeight: '500'}}>Contract Gross</span>
                    <TextInput onChange={(e) => onDataChange(e, 'contract_gross')} colors={props.colors} data={data.contract_gross} prefix='$'></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '20px'}}>
                    <span style={{fontWeight: '500'}}>Reason</span>
                    <TextInput onChange={(e) => onDataChange(e, 'reason')} colors={props.colors} data={data.reason} prefix=''></TextInput>
                </div>
                <Button onClick={() => props.onClick(data, props.reportIds, data.reason)} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main
