import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter'
import IconButton from '../../elements/IconButton'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'

import Save from '../../media/icons/icons8-save-96.png'

const Main = (props) => {

    console.log(props)

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    let [maxReportQuantity, setMaxReportQuantity] = useState(0)

    useEffect(() => {
        setMaxReportQuantity(props.reportQuantity)
    },[props.selectedThursdayReport])

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const DPPPCheck = () => {
        if (props.reportSplits.dppp !== 0 || props.DPPPOmitted === true)
        {
            return true
        }
        else
        {
            return false
        }
    }

    return (
        <div style={{borderRight: '1px solid ' + props.colors.border, paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', height: '70px', paddingTop: '10px'}}>
                <span style={{fontWeight: '500', fontSize: '20px'}}>REPORT 1</span>
                <IconButton onClick={() => props.clearReport()} img={false} Style={{border: '0', marginLeft: '15px', backgroundColor: props.colors.background}} colors={props.colors}>
                    <span style={{backgroundColor: props.colors.primary, color: props.colors.block, padding: '5px', borderRadius: '100px', paddingLeft: '11px', paddingRight: '11px'}}>X</span>
                </IconButton>
                <Counter onChange={props.onQuantityChange} allowNegative={false} maxValue={maxReportQuantity} minValue={props.isDisabled === true ? props.reportQuantity:0} colors={props.colors} Style={{height: '30px', marginLeft: '30px'}}>{props.reportQuantity}</Counter>
                <div style={{backgroundColor: props.colors.block, height: 'fit-content', padding: '2px', borderRadius: '100px', border: '1px solid ' + props.colors.border, paddingLeft: '10px', paddingRight: '10px', marginLeft: '10px'}}>
                    <span>Contract Count: {formatNumber(props.reportSplits.contractCount)}</span>
                </div>
                <span style={{marginLeft: '176px', fontSize: '16px', fontWeight: '600', visibility: props.default === true ? 'visible':'hidden'}}>Save Template</span>
                <IconButton colors={props.colors} onClick={() => props.setSaveTemplateModal(true)} image={Save} Style={{backgroundColor: props.colors.primary, border: 'none', borderRadius: '5px', marginLeft: '10px', padding: '4px', visibility: props.default === true ? 'visible':'hidden'}} size={'24px'}></IconButton>
            </div>
            <div style={{width: '100%', height: '130px'}}>
                <div style={{width: '100%', height: '100%', backgroundColor: props.colors.block, borderRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                    <div onClick={() => props.setFlatsOmitted(!props.flatsOmitted)} style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%', cursor: 'pointer'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span className='noSelect' style={{fontWeight: props.flatsOmitted ? '400':'600'}}>Flats</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.flats.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Splits</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.splits.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Gross</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.gross.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Net</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            {/* <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.net.toFixed(2)}</span> */}
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.splits.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Spiffs</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.spiffs.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>
                    {DPPPCheck() ? <div onClick={() => props.setDPPPOmitted(!props.DPPPOmitted)} style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%', cursor: "pointer"}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span className='noSelect' style={{fontWeight: props.DPPPOmitted ? '400':'600', cursor: 'pointer'}}>DPPP</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.dppp.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>:''}
                    {props.reportSplits.gap !== 0 ? <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>GAP</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.gap.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>:''}
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', flexBasis: '100%'}}>
                        <div className='verticalCenter' style={{flexBasis: '80%', borderBottom: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Inside Sales</span>
                        </div>
                        <div className='verticalCenter' style={{display: 'flex', flexDirection: 'column', flexBasis: '100%'}}>
                            <span style={{fontWeight: '500', fontSize: '20px'}}>${props.reportSplits.insideSales.toFixed(2)}</span>
                            <span style={{fontWeight: '500', fontSize: '14px', color: props.colors.muted}}>/contract</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', height: '400px', marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{width: '48.7%', height: '100%', backgroundColor: props.colors.block, marginRight: '10px', border: '1px solid ' + props.colors.border, borderRadius: '10px', display: 'flex', flexDirection: 'column', padding: '20px'}}>
                    <span style={{fontWeight: '500'}}>Report Type</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={!props.input} isDisabled={true} onChange={(e) => props.onChange(e, 0)} data={dealers2} colors={props.colors} value={props.tableData[0]} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Dealership</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={!props.input} isDisabled={true} Style={{marginTop: '5px'}} onChange={(e) => props.onChange(e, 1)} data={dealers2} colors={props.colors} value={props.tableData[1]} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Admin</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={!props.input} isDisabled={true} Style={{marginTop: '5px'}} onChange={(e) => props.onChange(e, 2)} data={dealers2} colors={props.colors} value={props.tableData[2]} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Contract Type</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={!props.input} isDisabled={true} Style={{marginTop: '5px'}} onChange={(e) => props.onChange(e, 3)} data={dealers2} colors={props.colors} value={props.tableData[3]} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                </div>
                <div style={{width: '48.7%', height: '100%', backgroundColor: props.colors.block, marginLeft: '10px', border: '1px solid ' + props.colors.border, borderRadius: '10px', display: 'flex', flexDirection: 'column', padding: '20px', overflowY: 'auto'}}>
                    <span style={{fontWeight: '500'}}>Template</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={!props.input} isDisabled={!props.input} onChange={(e) => props.onChange(e, 4)} data={props.templates} colors={props.colors} value={props.tableData[4]} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                        <div style={{height: '26px', display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontWeight: '500'}}>Spiffees</span>
                            <IconButton onClick={props.input === false ? () => '': () => {props.setSpiffeeModal(true); props.loadSpiffees(props.importedThursdayReports[props.selectedThursdayReport].dealership_ID)}} Style={{marginLeft: '10px', width: '26px', borderRadius: '5px', backgroundColor: props.colors.border}} colors={props.colors} img={false}><span style={{fontWeight: '600'}}>+</span></IconButton>
                        </div>
                        <div style={{height: 'auto', display: 'flex',  flexDirection: 'row', marginTop: '10px', flexWrap: 'wrap'}}>
                            {props.spiffees.map((spiffee, index) => {
                                return <span className='noSelect' onClick={() => props.input === false ? '':props.editSpiffee(index)} style={{backgroundColor: props.colors.border, padding: '3px', paddingLeft: '8px', paddingRight: '8px', borderRadius: '5px', margin: '3px', cursor: 'pointer'}}>{spiffee.name} | ${spiffee.amount}</span>
                            })}
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                        <div style={{height: '26px', display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontWeight: '500'}}>Reserve Inputs</span>
                            <IconButton onClick={props.input === false ? () => '': () => {props.setReserveInputModal(true); props.loadReserveAccounts(props.importedThursdayReports[props.selectedThursdayReport].dealership_ID)}} Style={{marginLeft: '10px', width: '26px', borderRadius: '5px', backgroundColor: props.colors.border}} colors={props.colors} img={false}><span style={{fontWeight: '600'}}>+</span></IconButton>
                        </div>
                        <div style={{height: 'auto', display: 'flex',  flexDirection: 'row', marginTop: '10px', flexWrap: 'wrap', width: '100%', maxWidth: '360px'}}>
                            {props.reserveInputs.map((spiffee, index) => {
                                return <span className='noSelect' onClick={() => props.input === false ? '':props.editReserveInput(index)} style={{backgroundColor: props.colors.border, padding: '3px', paddingLeft: '8px', paddingRight: '8px', borderRadius: '5px', margin: '3px', cursor: 'pointer'}}>{spiffee.account} | ${spiffee.amount}</span>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                {props.bottomButtons.length > 0 ? <Button onClick={() => props.bottomButtons[0].onClick()} Style={{backgroundColor: props.bottomButtons[0].backgroundColor}} active={true} colors={props.colors}>{props.bottomButtons[0].title}</Button>:''}
                {props.bottomButtons.length > 1 ? <Button onClick={() => props.bottomButtons[1].onClick()} Style={{marginLeft: 'auto', backgroundColor: props.bottomButtons[1].backgroundColor}} active={true} colors={props.colors}>{props.bottomButtons[1].title}</Button>:''}
            </div>
        </div>
    )
}

export default Main
