import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'

import EditSubagentModal from '../assets/manageSubagents/EditSubagentModal'
import OverrideCRModal from '../assets/manageSubagents/OverrideCRModal'
import SubagentDealersModal from '../assets/manageSubagents/SubagentDelearsModal'
import SubagentSpiffeesModal from '../assets/manageSubagents/SubagentSpiffeesModal'
import LateSubmissionOverrideModal from '../assets/manageSubagents/LateSubmissionOverrideModal'
import CreateSubagentModal from '../assets/manageSubagents/CreateSubagentModal'
import SubagentActionsModal from '../assets/manageSubagents/SubagentActionsModal'
import EditReserveAccountsModal from '../assets/manageSubagents/EditReserveAccountsModal'
import ExportData from '../assets/metrics/ExportData'

import Edit from '../media/icons/icons8-edit-96.png'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie' 

const Main = (props) => {

    const [cookies, setCookie, removeCookie, updateCookies] = useCookies([])
    const [skip, setSkip] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const debounceTimerRef = useRef();
    const [selectedSubagent, setSelectedSubagent] = useState(-1)
    const [subagents, setSubagents] = useState()

    const refreshData = () => {
        //setRefresh((refresh) => !refresh)
        refreshAllData();
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempContractList = []
        let tempDealerList = []
        let tempSubagentList = []
        let tempSpiffeesList = []

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
          }

        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: [dealer.id], label: dealer.name})
            })
        })

        props.tokenSafeAPIRequest('/spiffee/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempSpiffeesList.push({value: '', label: 'All Spiffees'})
            result.map((spiffee, index) => {
                tempSpiffeesList.push({value: [spiffee.id], label: spiffee.name})
            })
        })

        setFilterData([
            {
                key: 'new_agent_draw',
                options: [
                    {value: '', label: 'All'},
                    {value: true, label: 'Has Draw'},
                    {value: false, label: 'No Draw'},
                ],
                selectedValue: '',
                selectedDisplay: 'Draw Status'
            },
            {
                key: 'starter_deal',
                options: [
                    {value: '', label: 'All'},
                    {value: true, label: 'Has Deal'},
                    {value: false, label: 'No Deal'},
                ],
                selectedValue: '',
                selectedDisplay: 'Starter Deal Status'
            },
            {
                key: 'dealership_IDs',
                options: tempDealerList,
                selectedValue: '',
                selectedDisplay: 'Dealership'
            },
            {
                key: 'spiffee_IDs',
                options: tempSpiffeesList,
                selectedValue: '',
                selectedDisplay: 'Spiffee'
            },
            {
                key: 'date',
                options: [
                    {value: 30, label: '1 Month'},
                    {value: 90, label: '3 Months'},
                    {value: 180, label: '6 Months'},
                    {value: 365, label: '1 Year'},
                    {value: 'all', label: 'All'},
                ],
                selectedValue: '',
                selectedDisplay: 'Start Date'
            },
        ])
    },[])

    const [filterData, setFilterData] = useState([
        {
            key: 'new_agent_draw',
            options: [
                {value: '', label: 'All'},
                {value: true, label: 'Has Draw'},
                {value: false, label: 'No Draw'},
            ],
            selectedValue: '',
            selectedDisplay: 'Draw Status'
        },
        {
            key: 'starter_deal',
            options: [
                {value: '', label: 'All'},
                {value: true, label: 'Has Deal'},
                {value: false, label: 'No Deal'},
            ],
            selectedValue: '',
            selectedDisplay: 'Starter Deal Status'
        },
        {
            key: 'dealership_IDs',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Dealership'
        },
        {
            key: 'spiffee_IDs',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Spiffee'
        },
        {
            key: 'date',
            options: [
                {value: 30, label: '1 Month'},
                {value: 90, label: '3 Months'},
                {value: 180, label: '6 Months'},
                {value: 365, label: '1 Year'},
                {value: 'all', label: 'All'},
            ],
            selectedValue: '',
            selectedDisplay: 'Start Date'
        },
    ])

    const handleFilterDataChange = (index, value) => {
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value;
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        refreshAllData()
    }
    
    const searchElements = [
        (props) => (<div><TextInput placeholder='Search' colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0].options} width={195} value={filterData[0].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(1, e)} index={props.index} indexParent={props.indexParent} data={filterData[1].options} width={195} value={filterData[1].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(2, e)} index={props.index} indexParent={props.indexParent} data={filterData[2].options} width={195} value={filterData[2].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(3, e)} index={props.index} indexParent={props.indexParent} data={filterData[3].options} width={195} value={filterData[3].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(4, e)} index={props.index} indexParent={props.indexParent} data={filterData[4].options} width={195} value={filterData[4].selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Subagents')
            searchSubagents(e);
        }, 1000); // 3000 ms = 3 seconds
    }

    const formatDateFilters = (date) => {
        console.log(date)
        if (date === '' || date === 'all')
        {
            return '1923-01-01'
        }
        const today = new Date();
        const result = new Date(today);
        result.setDate(today.getDate() - date);
        let day = result.getDate();
        let month = result.getMonth() + 1; // getMonth() returns 0-11
        let year = result.getFullYear().toString(); // Get last two digits
        if (month < 10)
        {
            month = '0' + month
        }
        return `${year}-${month}-${day}`;
    }

    function setEmptyStringsToNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
                obj[key] = null;
            }
        });
        return obj;
    }

    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);
    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Edit Subagent',
            displayElement: (props) => (<IconButton onClick={() => {setEditSubagentModal(true); setSelectedSubagent(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: 0,
        },
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Number',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        // {
        //     title: 'Rate',
        //     displayElement: (props) => (<div style={{display: 'flex', flexDirection: 'row'}}>
        //                                     <span style={{marginTop: '6px', marginLeft: '20px', marginRight: '20px'}}>{props.data === '-' ? props.data:props.data * 100}%</span>
        //                                     <IconButton onClick={() => {setOverrideCRModal(true); setSelectedSubagent(props.index)}} Style={{backgroundColor: props.colors.primary, marginLeft: '5px', marginRight: '20px'}} size={31} image={Edit} colors={props.colors}></IconButton>
        //                                 </div>),
        //     default: undefined,
        // },
        {
            title: 'Start Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Draw',
            displayElement: (props) => (<div onClick={() => {editDraw(props.index, props.tableData, props.tableIDs); onTableChange(props.data === 0 ? 1:0, props.indexParent, props.index, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:''}}></div>),
            default: '',
        },
        {
            title: 'Starter Deal',
            displayElement: (props) => (<div onClick={() => {editStarterDeal(props.index, props.tableData, props.tableIDs); onTableChange(props.data === 0 ? 1:0, props.indexParent, props.index, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:''}}></div>),
            default: '',
        },
        {
            title: 'Dealers',
            displayElement: (props) => (<Button onClick={() => {setSubagentDealersModal(true); setSelectedSubagent(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>List</Button>),
            default: undefined,
        },
        {
            title: 'Spiffees',
            displayElement: (props) => (<Button onClick={() => {setSubagentSpiffeesModal(true); setSelectedSubagent(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>List</Button>),
            default: undefined,
        },
        {
            title: 'Late Submission',
            displayElement: (props) => (<Button onClick={() => {setLateSubmissionModal(true); setSelectedSubagent(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button>),
            default: undefined,
        },
        {
            title: 'Actions',
            displayElement: (props) => (<Button onClick={() => {setSubagentActionsModal(true); setSelectedSubagent(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>List</Button>),
            default: undefined,
        },
        {
            title: 'View Reserve Accounts',
            displayElement: (props) => (<Button onClick={() => {setEditReserveAccountsModal(true); setSelectedSubagent(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>View</Button>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Template
        [tableTitles[2].default], //Dealer
        [tableTitles[3].default], //Admin
        [tableTitles[4].default], //Contract Type
        [tableTitles[5].default], //Gross/Contract
        [tableTitles[6].default], //Edit Buttons (Empty as they don't need data)
        [tableTitles[7].default], //Admin
        [tableTitles[8].default], //Contract Type
        [tableTitles[9].default], //Gross/Contract
        [tableTitles[10].default], //Edit Buttons (Empty as they don't need data)
        [tableTitles[11].default], //Edit Buttons (Empty as they don't need data)
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
        [tableTitles[8].default],
        [tableTitles[9].default],
        [tableTitles[10].default],
        [tableTitles[11].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const [editSubagentModal, setEditSubagentModal] = useState(false)
    const [overrideCRModal, setOverrideCRModal] = useState(false)
    const [subagentDealersModal, setSubagentDealersModal] = useState(false)
    const [subagentSpiffeesModal, setSubagentSpiffeesModal] = useState(false)
    const [lateSubmissionModal, setLateSubmissionModal] = useState(false)
    const [createSubagentModal, setCreateSubagentModal] = useState(false)
    const [subagentActionsModal, setSubagentActionsModal] = useState(false)

    const onTableChange = (e, x, y, tableValuesIn, tableDataIn) => {
        console.log('Table Updated', e, x, y)
        setTableValues(() => tableValuesIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData(() => tableDataIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    function convertDateFormat(dateStr) {
        // Parse the date string
        var dateObj = new Date(dateStr);
    
        // Extracting date components
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are 0-based in JS
        var day = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear().toString().substr(-2);
    
        // Constructing the new date format
        return month + '/' + day + '/' + year;
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            subagent: true
            })
        }   

        props.tokenSafeAPIRequest('/user/search?limit=9999999&fetch_links=true&active_only=False' + '&start_date=' + (formatDateFilters(filterData[4].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[4].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            console.log(result)
            setSubagents(result)
            let tempSubagents = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempSubagentsData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((agent, index) => {
                tempSubagents[0].push('')
                tempSubagents[1].push(agent.code)
                tempSubagents[2].push(agent.first_name + ' ' + agent.last_name)
                tempSubagents[3].push(agent.phone_number)
                tempSubagents[4].push(convertDateFormat(agent.timestamp))
                tempSubagents[5].push(agent.new_agent_draw ? 1:0)
                tempSubagents[6].push(agent.starter_deal ? 1:0)
                tempSubagents[7].push(agent.dealership_connections)
                tempSubagents[8].push(agent.spiffee_IDs)
                tempSubagents[9].push(agent.late_submission)
                tempSubagents[10].push('')
                tempSubagents[11].push('')

                tempSubagentsData[0].push('')
                tempSubagentsData[1].push(agent.code)
                tempSubagentsData[2].push(agent.id)
                tempSubagentsData[3].push(agent.phone_number)
                tempSubagentsData[4].push(convertDateFormat(agent.timestamp))
                tempSubagentsData[5].push(agent.new_agent_draw ? 1:0)
                tempSubagentsData[6].push(agent.starter_deal ? 1:0)
                tempSubagentsData[7].push(agent.dealership_connections)
                tempSubagentsData[8].push(agent.spiffee_IDs)
                tempSubagentsData[9].push(agent.late_submission)
                tempSubagentsData[10].push('')
                tempSubagentsData[11].push('')
            })

            setTableValues(tempSubagents)
            setTableData(tempSubagentsData)
        })

        setCheckIfRan(true)
    },[])

    const [checkIfRan, setCheckIfRan] = useState(false)

    useEffect(() => {
        if (checkIfRan)
        {
            updateCookies();

            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            let formattedFilters = {}
            filterData.map((filter) => {
                if (filter.selectedValue === '')
                {
    
                }
                else
                {
                    if (filter.key === 'contract_type')
                    {
                        console.log(filter.selectedValue)
                        if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                        {
                            formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                        }
                        else
                        {
                            formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                        }
                    }
                    formattedFilters[filter.key] = filter.selectedValue
                }
            })
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({
                ...formattedFilters,
                subagent: true
                })
            }  
    
            props.tokenSafeAPIRequest('/user/search?limit=9999999&fetch_links=true&active_only=False' + '&start_date=' + (formatDateFilters(filterData[4].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[4].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
                if (status === 200)
                {
    
                } else {setNotification(props.getServerResponse(result))}
                console.log(result)
                setSubagents(result)
                let tempSubagents = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ];
                let tempSubagentsData = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ];
                result.map((agent, index) => {
                    tempSubagents[0].push('')
                    tempSubagents[1].push(agent.code)
                    tempSubagents[2].push(agent.first_name + ' ' + agent.last_name)
                    tempSubagents[3].push(agent.phone_number)
                    tempSubagents[4].push(convertDateFormat(agent.timestamp))
                    tempSubagents[5].push(agent.new_agent_draw ? 1:0)
                    tempSubagents[6].push(agent.starter_deal ? 1:0)
                    tempSubagents[7].push(agent.dealership_connections)
                    tempSubagents[8].push(agent.spiffee_IDs)
                    tempSubagents[9].push(agent.late_submission)
                    tempSubagents[10].push('')
                    tempSubagents[11].push('')
    
                    tempSubagentsData[0].push('')
                    tempSubagentsData[1].push(agent.code)
                    tempSubagentsData[2].push(agent.id)
                    tempSubagentsData[3].push(agent.phone_number)
                    tempSubagentsData[4].push(convertDateFormat(agent.timestamp))
                    tempSubagentsData[5].push(agent.new_agent_draw ? 1:0)
                    tempSubagentsData[6].push(agent.starter_deal ? 1:0)
                    tempSubagentsData[7].push(agent.dealership_connections)
                    tempSubagentsData[8].push(agent.spiffee_IDs)
                    tempSubagentsData[9].push(agent.late_submission)
                    tempSubagentsData[10].push('')
                    tempSubagentsData[11].push('')
                })
    
                if (searchElementValues[0] === '')
                {
                    setTableValues(tempSubagents)
                    setTableData(tempSubagentsData)
                }
                else
                {
                    searchSubagents(searchElementValues[0])
                }
            })
        }
    },[refresh])

    const refreshAllData = () => {
        updateCookies();

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            subagent: true
            })
        }  

        props.tokenSafeAPIRequest('/user/search?limit=9999999&fetch_links=true&active_only=False' + '&start_date=' + (formatDateFilters(filterData[4].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[4].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            console.log(result)
            setSubagents(result)
            let tempSubagents = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempSubagentsData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((agent, index) => {
                tempSubagents[0].push('')
                tempSubagents[1].push(agent.code)
                tempSubagents[2].push(agent.first_name + ' ' + agent.last_name)
                tempSubagents[3].push(agent.phone_number)
                tempSubagents[4].push(convertDateFormat(agent.timestamp))
                tempSubagents[5].push(agent.new_agent_draw ? 1:0)
                tempSubagents[6].push(agent.starter_deal ? 1:0)
                tempSubagents[7].push(agent.dealership_connections)
                tempSubagents[8].push(agent.spiffee_IDs)
                tempSubagents[9].push(agent.late_submission)
                tempSubagents[10].push('')
                tempSubagents[11].push('')

                tempSubagentsData[0].push('')
                tempSubagentsData[1].push(agent.code)
                tempSubagentsData[2].push(agent.id)
                tempSubagentsData[3].push(agent.phone_number)
                tempSubagentsData[4].push(convertDateFormat(agent.timestamp))
                tempSubagentsData[5].push(agent.new_agent_draw ? 1:0)
                tempSubagentsData[6].push(agent.starter_deal ? 1:0)
                tempSubagentsData[7].push(agent.dealership_connections)
                tempSubagentsData[8].push(agent.spiffee_IDs)
                tempSubagentsData[9].push(agent.late_submission)
                tempSubagentsData[10].push('')
                tempSubagentsData[11].push('')
            })

            if (searchElementValues[0] === '')
            {
                setTableValues(tempSubagents)
                setTableData(tempSubagentsData)
            }
            else
            {
                searchSubagents(searchElementValues[0])
            }
        })
    }

    const searchSubagents = (searchValue) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log('Searching By: ' + searchValue)

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            name: searchValue, 
            ...formattedFilters,
            subagent: true
            })
        }  

        props.tokenSafeAPIRequest('/user/search?limit=10000&fetch_links=true&active_only=false' + '&start_date=' + (formatDateFilters(filterData[4].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[4].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            console.log(result)
            setSubagents(result)
            let tempSubagents = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempSubagentsData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((agent, index) => {
                tempSubagents[0].push('')
                tempSubagents[1].push(agent.code)
                tempSubagents[2].push(agent.first_name + ' ' + agent.last_name)
                tempSubagents[3].push(agent.phone_number)
                tempSubagents[4].push(convertDateFormat(agent.timestamp))
                tempSubagents[5].push(agent.new_agent_draw ? 1:0)
                tempSubagents[6].push(agent.starter_deal ? 1:0)
                tempSubagents[7].push(agent.dealership_connections)
                tempSubagents[8].push(agent.spiffee_IDs)
                tempSubagents[9].push(agent.late_submission)
                tempSubagents[10].push('')
                tempSubagents[11].push('')

                tempSubagentsData[0].push('')
                tempSubagentsData[1].push(agent.code)
                tempSubagentsData[2].push(agent.id)
                tempSubagentsData[3].push(agent.phone_number)
                tempSubagentsData[4].push(convertDateFormat(agent.timestamp))
                tempSubagentsData[5].push(agent.new_agent_draw ? 1:0)
                tempSubagentsData[6].push(agent.starter_deal ? 1:0)
                tempSubagentsData[7].push(agent.dealership_connections)
                tempSubagentsData[8].push(agent.spiffee_IDs)
                tempSubagentsData[9].push(agent.late_submission)
                tempSubagentsData[10].push('')
                tempSubagentsData[11].push('')
            })

            setTableValues(tempSubagents)
            setTableData(tempSubagentsData)
        })
    }

    const [notification, setNotification] = useState('')

    const addNewSubagent = (newSubagentInfo) => {

        console.log(newSubagentInfo)

        newSubagentInfo = {...newSubagentInfo, role: 'subagent', subagent: true, state_code: newSubagentInfo.state_code.toUpperCase()}

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newSubagentInfo)
        };

        props.tokenSafeAPIRequest('/user', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setCreateSubagentModal(false)
                setNotification('Created Subagent Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editSubagent = (newSubagentInfo) => {
        console.log(newSubagentInfo)

        newSubagentInfo = setEmptyStringsToNull(newSubagentInfo);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newSubagentInfo)
        };

        props.tokenSafeAPIRequest('/user/' + newSubagentInfo.id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditSubagentModal(false)
                setNotification('Edited Subagent Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editSubagentRate = (data, id) => {
        console.log(data, id)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                commission_rate: (data.commission_rate/100),
                commission_expiry: calculateFutureDate(data.duration, data.duration_type, data.unlimited)
            })
        };

        props.tokenSafeAPIRequest('/user/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setOverrideCRModal(false)
                setNotification('Updated Commission Rate Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    function calculateFutureDate(duration, durationType, addCentury) {
        console.log(duration, durationType)
        if ((duration === '' || durationType === '') && addCentury === false)
        {
            return '';
        }
        let currentDate = new Date();
        console.log(currentDate)
        let futureDate = new Date();

        console.log(currentDate.getDate() + Number(duration))
    
        switch (durationType) {
            case "days":
                futureDate.setDate(futureDate.getDate() + Number(duration));
                break;
            case "weeks":
                futureDate.setDate(futureDate.getDate() + Number(duration) * 7);
                break;
            case "months":
                futureDate.setMonth(futureDate.getMonth() + Number(duration));
                break;
            case "years":
                futureDate.setFullYear(futureDate.getFullYear() + Number(duration));
                break;
            default:

        }
    
        if (addCentury === true) {
            futureDate.setFullYear(futureDate.getFullYear() + 100);
        }

        console.log(futureDate)
    
        return futureDate;
    }

    const submitLateSubmission = (data, id) => {

        console.log(data, id)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        props.tokenSafeAPIRequest('/user/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setLateSubmissionModal(false)
                setNotification('Updated Submission Time Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const deleteSubagent = (id) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditSubagentModal(false)
                setNotification('Deleted Subagent Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [editReserveAccountsModal, setEditReserveAccountsModal] = useState(false)

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const editReserveAccounts = (newReserveAccounts, deletedAccounts) => {
        let existingAccounts = []
        let newAccounts = []

        console.log(deletedAccounts)
        console.log(newReserveAccounts)

        transposeArray(newReserveAccounts).map((account, index) => {
        if (account[2] === '')
            {
                newAccounts.push({
                    name: account[0],
                    notes: account[1],
                    user_owner_ID: transposeArray(tableData)[selectedSubagent][2],
                    account_type: 2
                })
            }
            else 
            { 
                existingAccounts.push({
                    name: account[0],
                    notes: account[1],
                    id: account[2],
                    account_type: 2
                })
             }
        })
        
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(newAccounts)
        console.log(existingAccounts)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newAccounts)
        };

        props.tokenSafeAPIRequest('/account/import', requestOptions, tokenValues, (result, status) => {
            console.log(result)

            if (status === 200)
            {
                if (result.length > 0)
                {
                    setNotification('Added New Reserve Accounts Successfully at: ' + getCurrentTimeFormatted())
                }
            } else {setNotification(props.getServerResponse(result))}

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(existingAccounts)
            };

            props.tokenSafeAPIRequest('/account/bulk', requestOptions, tokenValues, (result2, status2) => {
                console.log(result2)

                if (status2 === 200)
                {
                    setNotification('Edited Existing Reserve Accounts Successfully at: ' + getCurrentTimeFormatted())
                } else {setNotification(props.getServerResponse(result2))}

                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(deletedAccounts)
                };

                props.tokenSafeAPIRequest('/account/bulk', requestOptions, tokenValues, (result3, status3) => {
                    console.log(result3)
                    if (status3 === 200)
                    {
                        if (deletedAccounts.length > 0)
                        {
                            setNotification('Deleted Reserve Accounts Successfully at: ' + getCurrentTimeFormatted())
                        }
                    } else {
                        if (deletedAccounts.length !== 0)
                        {
                            setNotification(props.getServerResponse(result3))
                        }
                    }
                    setEditReserveAccountsModal(false)
                    refreshData()
                })
            })
        })
    }

    const editDraw = (rowIndex, tableValues, tableData) => {

        console.log(rowIndex)
        console.log(tableData)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                new_agent_draw: !transposeArray(tableData)[rowIndex][6]
            })
        };

        console.log(!transposeArray(tableData)[rowIndex][6])

        props.tokenSafeAPIRequest('/user/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditSubagentModal(false)
                setNotification('Edited Subagent Successfully at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editStarterDeal = (rowIndex, tableValues, tableData) => {

        console.log(rowIndex)
        console.log(tableData)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                starter_deal: !transposeArray(tableData)[rowIndex][7]
            })
        };

        console.log(!transposeArray(tableData)[rowIndex][7])

        props.tokenSafeAPIRequest('/user/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditSubagentModal(false)
                setNotification('Edited Subagent Successfully at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const addBonusDeduction = (data, id, existingBonusesDeductions) => {
        console.log(data, id)
        console.log(existingBonusesDeductions)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        data.bonuses_deductions.map((change, index) => {
            if (change.type === 'Bonus')
            {
                return change;
            }
            else
            {
                change.amount = -change.amount
                return change;
            }
        })

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                bonuses_deductions: [...existingBonusesDeductions, ...data.bonuses_deductions]
            })
        };

        console.log(data.bonuses_deductions)

        props.tokenSafeAPIRequest('/user/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Edited Bonuses/Deductions Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const setDealershipOverride = (data, id, connections) => {
        console.log(data, id, connections)

        let connectionID = '';

        connections.map((dealer, index) => {
            if (dealer.dealership_ID === data.new_dealership_id)
            {
                console.log('Found Dealer!')
                connectionID = dealer.id;
            }
            return dealer
        })

        console.log(connectionID, data.new_dealership_rate)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                commission_rate: data.new_dealership_rate/100
            })
        };

        props.tokenSafeAPIRequest('/connection/' + connectionID, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Edited Dealership Commission Rate Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const sendNotification = (message) => {
        setNotification(message)
    }

    const [exportSubagents, setExportSubagents] = useState(false)

    const exportKeys = [{id: 'code', label: 'Subagent Code'}, {id: 'name', label: 'Name'}, {id: 'phone_number', label: 'Phone Number'}, {id: 'timestamp', label: 'Start Date'} , {id: 'new_agent_draw', label: 'Draw'}, {id: 'starter_deal', label: 'Starter Deal'}]

    const exportPostProcessing = () => {

    }

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} buttonText={'Add New Subagent'} buttonText2={'Export Subagents'} onClick2={() => setExportSubagents(true)} title='Manage Subagents' onClick={() => setCreateSubagentModal(true)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => ''}></ManageTable>
            <ModalManager colors={props.colors}>
                <EditSubagentModal colors={props.colors} isOpen={editSubagentModal} editSubagent={editSubagent} deleteSubagent={deleteSubagent} subagents={subagents} selectedSubagent={selectedSubagent} setIsOpen={() => setEditSubagentModal(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditSubagentModal>
                <OverrideCRModal colors={props.colors} isOpen={overrideCRModal} setIsOpen={() => setOverrideCRModal(false)} editSubagentRate={editSubagentRate} subagents={subagents} selectedSubagent={selectedSubagent} addBonusDeduction={addBonusDeduction} tokenSafeAPIRequest={props.tokenSafeAPIRequest} setDealershipOverride={setDealershipOverride}></OverrideCRModal>
                <SubagentDealersModal colors={props.colors} isOpen={subagentDealersModal} subagents={subagents} selectedSubagent={selectedSubagent} setIsOpen={() => setSubagentDealersModal(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></SubagentDealersModal>
                <SubagentSpiffeesModal colors={props.colors} isOpen={subagentSpiffeesModal} subagents={subagents} selectedSubagent={selectedSubagent} setIsOpen={() => setSubagentSpiffeesModal(false)}></SubagentSpiffeesModal>
                <LateSubmissionOverrideModal colors={props.colors} isOpen={lateSubmissionModal} setIsOpen={() => setLateSubmissionModal(false)} submitLateSubmission={submitLateSubmission} subagents={subagents} selectedSubagent={selectedSubagent}></LateSubmissionOverrideModal>
                <CreateSubagentModal colors={props.colors} isOpen={createSubagentModal} addNewSubagent={addNewSubagent} setIsOpen={() => setCreateSubagentModal(false)}></CreateSubagentModal>
                <SubagentActionsModal colors={props.colors} isOpen={subagentActionsModal} setIsOpen={() => setSubagentActionsModal(false)} getServerResponse={props.getServerResponse} sendNotification={sendNotification} subagents={subagents} selectedSubagent={selectedSubagent} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></SubagentActionsModal>
                <EditReserveAccountsModal colors={props.colors} isOpen={editReserveAccountsModal} setIsOpen={() => setEditReserveAccountsModal(false)} editReserveAccounts={editReserveAccounts} subagents={subagents} selectedSubagent={selectedSubagent}></EditReserveAccountsModal>
                <ExportData colors={props.colors} isOpen={exportSubagents} setIsOpen={() => setExportSubagents(false)} url={'/user/search'} filterData={{}} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'List of all Dealers Export'} calStartDate={'1900-01-01'} calEndDate={'2100-01-01'}></ExportData>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
